export const ProposalConst = {

    proposalBy: {
        COMPANY: 'COMPANY',
        STANDARD: 'STANDARD',
    },
    surChargeType: {
        FIX: 'FIX',
        PERCENT: 'PERCENT',
    },
}